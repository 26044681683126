<template>
  <div>
    <div>
      <strong>Tarifa inicial: </strong>
      <b-badge variant="warning" >
        {{ room.detailSelected.displayrates.CurrencyCode }} {{formatAmount(room.displayrates.priceInit)}}
      </b-badge>
    </div>
    <div v-if="room.detailSelected.PromotionName != ''">
      <strong>Promoción: </strong>{{room.detailSelected.PromotionName}}
    </div>
    <div class="mb-1">
      <strong>Total: </strong> {{ room.detailSelected.displayrates.CurrencyCode }} {{ formatAmount(room.detailSelected.displayrates.AmountAfterTax)}}
    </div>
    <b-row align-v="end" v-if="!isLoadingRates">
      <b-col>
        <div class="mb-half">Descuento</div>
        <b-form-select
          v-model="room.detailSelected.PromotionCode"
          @change="checkPriceMatchByPromotionCode"
        >
          <option value="">Seleccione descuento</option>
          <option v-for="promoCode in promoCodes" :key="promoCode.id" :value="promoCode.name">{{promoCode.name}}</option>
        </b-form-select>
      </b-col>

      <b-col>
        <div class="float-right" v-if="room.rates.length">
          <b-button variant="primary" @click="handlerAddToCart" :disabled="!room.showingRates">
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
            /> Añadir
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div class="text-center mt-3" v-if="isLoadingRates">
      <b-spinner label="Loading..." variant="success"/><br>
      <strong>Buscando tarifa</strong>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { globalCurrencyFormat, toDecimal, toJson } from '@/helpers/helpers'

export default {
  props:{
    room:{
      type: Object,
      required: true,
    },
    isLoadingRates:{
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isLoadingRatesPriceMatch: false
    }
  },
  computed:{
		...mapState('start',['promoCodes', 'currencies']),
    ...mapState('bookings',['searchForms']),
    ...mapState('auth',['user']),
  },
  methods:{
    checkPriceMatchByPromotionCode( promoCode ){
      const searchForm = toJson(this.searchForms.find( item => item.fromTab === this.room.fromTab ))
      // console.log(searchForm, promoCode)
      const { hotel, idioma, dateIn, dateOut, ninos, adults, children, vendorcode } = searchForm
      const { RoomTypeCode, keyRoom, priceInit } = this.room
      if( promoCode ){
        this.room.detailSelected.allowPriceMatch = false  //oculto tab para price match manual rate
        const payload = {
          adults,
          children,
          dateIn,
          dateOut,
          hotel,
          idioma,
          isManualRate: false,
          keyRoom, //lo ocupo de manera interna
				  manualRate: "", //lo ocupo de manera interna
          ninos,
          priceInit, //lo ocupo de manera interna
          promotionCode: promoCode, //lo que viene del select
          rateplanid: "",
          roomCode: RoomTypeCode,
          tarifa: this.room.detailSelected.RatePlanCode,
          vendorcode
        }
        // console.log( payload )
        this.$emit('check-price-match-by-promotion-code', payload )
      }
      if(!promoCode){
        this.room.detailSelected.allowPriceMatch = true
        if( this.room.detailSelected.AmountAfterTax != this.room.detailSelected.priceInit ){
          this.$emit('price-match-initial')
        }
      }

    },
    handlerAddToCart(){
			const codePromocion = this.room?.detailSelected?.PromotionCode || ''	//optional chaining
      let resultado = null
      if( codePromocion != '' ){
        resultado = toJson(this.promoCodes.find( item => item.name === codePromocion ))
      }
      const requiresAuthorization = resultado ? resultado.authorization : false
      const payload = {
        authorizationUser: null,
        dateAuthorization: null,
        isAuthorization: false, // no es autorizacion ya que no hay idUser
        isRateLimit: false,
        urlAuthorization: '',
        requiresAuthorization
      }
      this.$emit('handler-add-to-cart', payload)
    },
    formatAmount(amount){
      return globalCurrencyFormat(amount)
    },
  }
}
</script>

<style>
.custom-margin-promo-code{
  margin: 0.3rem;
}
</style>
