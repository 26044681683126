<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <div class="float-right">
          <b-form-checkbox
            v-model="isRateLimit"
            :value="true"
            class="custom-control-success"
          > Es tarifa límite
          </b-form-checkbox>
        </div>
        <div>
          <!-- <pre>{{ room }}</pre> -->
          <strong>Tarifa inicial: </strong>
          <b-badge variant="warning" :class="room.detailSelected.priceMatchTax != ''? 'underline-price':''">
            {{ room.detailSelected.displayrates.CurrencyCode }} {{formatAmount(room.displayrates.priceInit)}}
          </b-badge>
        </div>
        <div v-if="room.detailSelected.PromotionName != ''">
          <strong>Promoción: </strong>{{room.detailSelected.PromotionName}}
        </div>

        <!-- <div v-if="room.showingRates">
          <strong>Tarifa manual:</strong> {{formatAmount(room.detailSelected.manualPrice)}}
        </div> -->
        <div v-if="!!precioToUse">
          <strong>Total: </strong> {{ room.detailSelected.displayrates.CurrencyCode }} {{precioToUse }}
        </div>
      </b-col>
    </b-row>
    <b-row align-v="end">
      <b-col md="">
        <div v-if="!isLoadingRates">
          <div class="mb-half">Tarifa ({{currency.code}})</div>
          <b-form-input
            placeholder="Tarifa"
            type="text"
            @keypress="onlyNumber"
            @change="setManualPrice"
            v-model="room.detailSelected.displayrates.manualPrice"
          />
        </div>
        <div class="text-center" v-if="isLoadingRates">
            <b-spinner label="Loading..." variant="success"/><br>
            <strong>Buscando tarifa</strong>
        </div>
      </b-col>

      <b-col md="4" class="text-right">
        <b-button
          variant="primary"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="room.detailSelected.displayrates.manualPrice == '' || !room.showingRates || isLoadingRates"
          @click="checkPriceMatchByManualRate"
          title="Buscar por tarifa manual"
          ><feather-icon icon="SearchIcon"/>
        </b-button>
          <!-- v-b-tooltip.hover.left="'Buscar por tarifa manual'" -->

      </b-col>

      <b-col cols="12" class="text-right mt-1" v-if="!isLoadingRates">
        <b-button
          v-if="room.detailSelected.PromotionName != '' && !isRateLimit"
          variant="primary"
          @click="handlerAddCart"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.top="'Autorizar tarifa manual'"
        > <feather-icon icon="UnlockIcon"/> Autorizar</b-button>
        <b-button
          v-if="room.detailSelected.PromotionName != '' && isRateLimit"
          variant="primary"
          @click="handlerAddCart"
          :disabled="!room.showingRates"
          >
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
          /> Añadir
        </b-button>

      </b-col>
    </b-row>
    <AuthorizeBooking
      :room="room"
      @set-payload-add-to-cart="handlerAddToCart"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { toJson, toDecimal,stringAleatorio, currentDate, globalCurrencyFormat } from '@/helpers/helpers'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AuthorizeBooking from '@/modules/shop/components/bookings/AuthorizeBooking'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    room:{
      type: Object,
      required: true,
    },
    isLoadingRates:{
      type: Boolean,
      required: true,
    }
  },
  components: {
    AuthorizeBooking
  },
  data() {
		return {
      isRateLimit: false,
		}
	},
  computed:{
		...mapState('shop',['currency']),
		...mapState('start',['currencies']),
    ...mapState('bookings',['searchForms','userAuthorizeRate']),
    ...mapState('auth',['user']),
    precioToUse(){
      const precioToUse = (this.room.detailSelected.displayrates.priceMatchTax != '' ) ? this.room.detailSelected.displayrates.priceMatchTax : this.room.detailSelected.displayrates.AmountAfterTax
      return globalCurrencyFormat(precioToUse || 0.00)
    },
    exchangeRate(){
      return (Math.round( (this.room.detailSelected.divisa.value) * 100) / 100).toFixed(2)
    }
  },
  methods:{
    ...mapMutations('bookings',['setManualPriceSelectedRate']),
    setManualPrice(){
     const keyRoom = this.room.keyRoom
     const manualRate = this.room.detailSelected.manualPrice
     const payload = { keyRoom, manualRate }
     this.setManualPriceSelectedRate(payload)
    },
    onlyNumber(event){
			let keyCode = (event.keyCode ? event.keyCode : event.which)
			if ((keyCode < 48 || keyCode > 57) ) { // 46 is dot
				event.preventDefault()
			}
		},
    checkPriceMatchByManualRate(){
      const searchForm = toJson(this.searchForms.find( item => item.fromTab === this.room.fromTab ))
      const { hotel, idioma, dateIn, dateOut, ninos, adults, children, vendorcode } = searchForm
      const { RoomTypeCode, keyRoom, priceInit } = this.room
      const manualRateFormat = parseFloat( this.room.detailSelected.displayrates.manualPrice )
      const priceInitFormat = parseFloat( priceInit )
      const requiresRecalc = this.room.detailSelected.CurrencyCode != this.currency.code

        this.room.detailSelected.allowPriceMatch = true  //muestro tab para price match manual rate
        const payload = {
          adults,
          children,
          dateIn,
          dateOut,
          hotel,
          idioma,
          isManualRate: true,
          keyRoom, //lo ocupo de manera interna
				  manualRate: manualRateFormat, //requirido para price match manual price
          ninos,
          priceInit, //lo ocupo de manera interna
          promotionCode: '', //vacío
          rate: priceInitFormat, //requirido para price match manual price
          rateplanid: "",
          roomCode: RoomTypeCode,
          tarifa: this.room.detailSelected.RatePlanCode,
          vendorcode,
          requiresRecalc
        }

      this.$emit('check-price-match-by-promotion-code', payload )
    },
    handlerAddCart(){
      if( this.isRateLimit ){
        const payload = {
          authorizationUser: null,
          dateAuthorization: null,
          isAuthorization: false,
          isRateLimit: true,
          requiresAuthorization: false,
          urlAuthorization: null,
        }
      this.$emit('handler-add-to-cart', payload)
      }
      if(!this.isRateLimit){
        this.openModal()
      }
    },
    handlerAddToCart(){
      const { idUser, urlAuthorization } = this.userAuthorizeRate
      this.closeModal()
      const payload = {
        authorizationUser: idUser,
        dateAuthorization: currentDate(),
        isAuthorization: true,
        isRateLimit: false,
        requiresAuthorization: false,
        urlAuthorization
      }
      this.$emit('handler-add-to-cart', payload)
    },
    openModal(){
			this.$root.$emit('bv::show::modal', 'authorize-rate'+this.room.keyRoom)
    },
    closeModal(){
			this.$root.$emit('bv::hide::modal', 'authorize-rate'+this.room.keyRoom)
    },
    formatAmount(amount){
      return globalCurrencyFormat(amount)
    },
  }
}
</script>

<style>
.custom-margin-price-match{
  margin: 0.3rem !important;
}
</style>
