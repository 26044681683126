<template>
    <div>
        <div class="float-right" v-if="room.detailSelected.AmountAfterTax != room.detailSelected.priceInit || !room.rates.length">
            <b-button
                size="sm"
                variant="warning"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Regresar a tarifa original'"
                @click="priceMatchInitial"
            > <feather-icon icon="RotateCcwIcon"/>
            </b-button>
        </div>

        <b-tabs v-if="room.rates.length" >
            <b-tab active title="Descuento" >
                <PriceMatchPromoCode
                    :room="room"
                    :isLoadingRates="isLoadingRates"
                    @check-price-match-by-promotion-code="priceMatchRoom"
                    @price-match-initial="priceMatchInitial"
                    @handler-add-to-cart="addToCart"
                />
            </b-tab>
            <!-- -->
            <b-tab title="Price Match / Tarifa límite" v-if="room.detailSelected.allowPriceMatch">
                <PriceMatchManualPrice
                    :room="room"
                    :isLoadingRates="isLoadingRates"
                    @check-price-match-by-promotion-code="priceMatchRoom"
                    @handler-add-to-cart="addToCart"
                />
            </b-tab>
        </b-tabs>
        <b-tabs v-if="!room.rates.length">
            <b-tab active title="Sín tarifas" ></b-tab>
                <b-alert show variant="danger"  class="mt-2">
                <div class="alert-body text-center"> <span>Su busqueda no encontró tarifas para esta habitación, click en el botón de regresar a tarifa original e intente con otra opcion de tarifas </span></div>
                </b-alert>
        </b-tabs>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PriceMatchPromoCode from '@/modules/shop/components/bookings/PriceMatchPromoCode'
import PriceMatchManualPrice from '@/modules/shop/components/bookings/PriceMatchManualPrice'
import {toJson} from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'

export default {
    mixins: [ market ],
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props: {
		room:{
			type: Object,
			required: true
		},
        isLoadingRates:{
            type: Boolean,
            required: true,
        }
	},
    components:{
        PriceMatchPromoCode,
        PriceMatchManualPrice
    },
    data(){
        return {
            imgUrl: `https://backsales.thefives.com.mx/media/imgdefault.jpg`,
        }
    },
    computed:{
        ...mapState('bookings',['searchForms']),
        ...mapState('start',['hotels']),
    },
    methods:{
        ...mapActions('shop',['calculateTotalWithDiscounts']),
        ...mapActions('bookings',['fetchRoomPriceMatch']),
        ...mapMutations('bookings',['setRooms']),
		...mapMutations('shop',['addBookingInCart']),

        async priceMatchRoom( data ){
            this.$emit('set-is-loading-rates', true)
            data.allowPriceMatch = data.isManualRate // si es isManualRate, allowPriceMatch es true
            const rooms = await this.fetchRoomPriceMatch(data) //pido nuevas tarifas y obtengo todos los rooms con una modificada en especifico
            this.setRooms(rooms)
            this.$emit('set-is-loading-rates', false)
        },
        async priceMatchInitial(){
            this.$emit('set-is-loading-rates', true)
            const searchForm = toJson(this.searchForms.find( item => item.fromTab === this.room.fromTab ))
            const { RoomTypeCode, keyRoom,  priceInitDefault } = this.room
            const payload = {...searchForm }
            payload.roomCode = RoomTypeCode
            payload.keyRoom = keyRoom, //lo ocupo de manera interna
            payload.priceInit = priceInitDefault, //lo ocupo de manera interna
            payload.allowPriceMatch = true
            const rooms = await this.fetchRoomPriceMatch(payload) //pido nuevas tarifas y obtengo todos los rooms con una modificada en especifico
            this.setRooms(rooms)
            this.$emit('set-is-loading-rates', false)
        },
        async addToCart( data ){
            const { authorizationUser, urlAuthorization, dateAuthorization, isRateLimit, requiresAuthorization } = data
            const searchForm = toJson(this.searchForms.find( item => item.fromTab === this.room.fromTab ))
            const { idHotel, nights, vendorcode } = searchForm
            const { detailSelected, multimedias, RoomTypeCode, RoomTypeName, priceInit, displayrates } = toJson(this.room)
            const { InfoRates, RatePlanCode, AmountAfterTax, PromotionCode, RatePlanID, manualPrice, MealPlanDescription, displayrates:detaildisplayrates } = detailSelected

            const payload = {
                categoryName: 'Reservas',
                dateAuthorization,
                descmax: 0,
                detaildisplay: `${RoomTypeCode} | ${RoomTypeName}`,
                detailId: RoomTypeCode,
                extrapax: false,
                hotel: idHotel,
                hotelName: this.hotels.find( hotel => hotel.id === idHotel ).name,
                idUserAuthorizeRate: authorizationUser,
                img: (multimedias.length > 0 && multimedias[0].multimedia != '' ) ? multimedias[0].multimedia : this.imgUrl,
                infoRates: InfoRates,
                isRateLimit,
                modelType : 'booking',
                multimedias: multimedias.length > 0 ? toJson(multimedias) : null,
                nights,
                promoCode: PromotionCode,
                rateInit: priceInit,
                rateManual: manualPrice,
                rateplanid: RatePlanID,
                rateplanname: MealPlanDescription,
                requiresAuthorization,
                roomtypename: RoomTypeName,
                saleprice: AmountAfterTax,
                serviciosihot: null,
                urlAuthorization,
                vendorcode,
                ...detailSelected,
                ...searchForm,
                tarifa: RatePlanCode
            }

            if (displayrates && detaildisplayrates) {
                payload.displayrates = {
                    ...displayrates,
                    ...detaildisplayrates,
                    infoRates: detaildisplayrates.currencyinforates,
                    rateInit: displayrates.priceInit,
                    saleprice: detaildisplayrates.AmountAfterTax
                }
            }
            await this.manageAddProductInCart(payload, payload.categoryName)

        }
    }
}
</script>