<template>
	<div>	
		<div class="tabs">			
			<div class="tab-content">
				<Tabs v-if="hasVendorCode"/>
				<b-card v-else>
					<b-card-body>
						<b-alert variant="danger" :show="true" class="mb-0" >
							<div class="alert-body">
								<feather-icon icon="InfoIcon" class="mr-50" />
								En su cuenta de usuario no se le encontró un <b>vendor code</b>, necesario para el proceso de reservas. Contacte a sistemas para que se le asigne uno respectivamente..
							</div>
							
						</b-alert>
						<div class="text-center mt-2">
							<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.v-primary title="Marketplace" variant="primary" :to="{name: 'sales'}" >
								Ir a inicio
							</b-button>
						</div>
					</b-card-body>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Tabs from '@/modules/shop/components/bookings/Tabs'
import Ripple from 'vue-ripple-directive'

export default {
	directives: {
		Ripple,
	},
	components: {		
		Tabs
	},
	created() {
		this.resetBookingsModule()
	},
	beforeDestroy(){
		this.resetBookingsModule()
	},
	computed:{
    	...mapState('auth',['user']),
		hasVendorCode(){
			return this.user.canalventa?.dingusvendorcode ? true : false       
		}
	},
	methods: {
		...mapMutations("bookings", ["resetBookingsModule"])
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>