<template>
    <b-row>
        <b-col md="8">
            <div class="d-flex justify-content-between align-items-center" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-if="room.rates.length">
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <b-avatar size="42" :src="require('@/assets/images/salescore/room-icon.png')" />
                </b-media-aside>

                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    Disponibles
                  </h6>
                  <small>
                    <b>{{room.NumberOfUnits}} </b>Hab. Disponibles</small>
                </b-media-body>
              </b-media>
            </div>
        </b-col>
        <b-col md="4" v-if="room.detailSelected.InfoRates && room.detailSelected.InfoRates.length">
           <div
            class="float-right"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :id="'btn-info'+room.keyRoom"
          >
            <b-media no-body :id="'btn-info'+room.keyRoom">
                <b-media-body >
                </b-media-body>
                <b-media-aside >
                  <b-button
                    v-b-tooltip.hover.top="'Ver más detalles de tarifa'"
                    variant="dark"
                    class="btn-icon"
                    @click="showModal"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                />
                </b-button>
                </b-media-aside>
              </b-media>
            </div>
        </b-col>
        <b-modal
          :id="'rate-detail'+room.keyRoom" :title="room.RoomTypeName"
          size="md"
          ok-only centered
        >
      <table class="table table-sm table-striped" v-if="room.detailSelected.InfoRates && room.detailSelected.InfoRates.length">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Fecha entrada</th>
            <th scope="col">Fecha salida</th>
            <th scope="col">Tarifa</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ratesInfoSort" :key="index">
            <td scope="row">{{ item.DateIn}} </td>
            <td scope="row">{{ item.DateOut }}</td>
            <td scope="row">{{ room.detailSelected.displayrates.CurrencyCode }} {{ formatAmount(item.currencyRate) }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </b-row>
</template>
<script>
import StatisticCardItems from '@core/components/statistics-cards/StatisticCardItems'
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { globalCurrencyFormat } from '@/helpers/helpers'


export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    room: {
      type: Object,
      required: true
    },
  },
  components: {
    StatisticCardItems,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    ratesInfoSort(){
      this.room.detailSelected.InfoRates.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.DateIn) - new Date(b.DateIn);
      });
      return this.room.detailSelected.InfoRates
    }
  },
  data(){
    return {
    }
  },
  methods:{
    showModal(){
      this.$root.$emit("bv::show::modal", "rate-detail" + this.room.keyRoom)
    },
    formatAmount(amount){
      return globalCurrencyFormat(amount)
    }
  }
}
</script>
<style scoped>
.popover{
    max-width: 700px;
    /* Max Width of the popover (depending on the container!) */
}
</style>
